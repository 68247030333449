import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      primaryLight: '#FFFFFF',
      neutralLight1: '#FAF7F5',
      neutralLight2: '#C9966F',
      neutralLight3: '#B88965',
      neutralLight4: '#F5F1ED',
      neutralLight5: '#785942',
      neutralLight6: '#E6E3D9',
      neutralLight7: '#FDFBF4',
      neutralDark1: '#646464',
      neutralDark2: '#D1D1D1',
      primaryDark: '#1A1919',
      danger: '#ED0F36',
    },
  },
  fontFamily: {
    heading: "'miller-display', serif",
    paragraph: "'sofia-pro', sans-serif",
  },
}

export type Styles = typeof styles

export const styles = {
  h1: css`
    font-size: 6.25rem;
    line-height: 1;
    letter-spacing: 0.02em;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.neutralLight1};

    @media (max-width: 1199px) {
      font-size: 2.5rem;
      line-height: 2.8125rem;
    }
  `,
  h2: css`
    font-size: 3.875rem;
    margin-bottom: 2.8125rem;
    line-height: 1.16;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 1199px) {
      font-size: 2.5rem;
      margin-bottom: 2.8125rem;
    }
  `,
  h3: css`
    font-size: 2.5rem;
    line-height: 1;
    letter-spacing: 0.024em;
    line-height: 1.3;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 1199px) {
      font-size: 1.5rem;
    }
  `,
  h4: css`
    font-size: 1.875rem;
    line-height: 1.4;
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.heading};
  `,
  label: css`
    font-size: 1rem;
    margin-bottom: 0.9375rem;
    line-height: 1.125;
    letter-spacing: 0.4em;
    font-weight: 300;
    text-transform: uppercase;
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.neutralLight2};
  `,
  description: css`
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.875;
    letter-spacing: 0.024em;
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.neutralDark1};
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        <ParallaxProvider>{children}</ParallaxProvider>
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
